import { configClass } from "@/modules/configClass.js";

import { userClass } from "@/modules/userClass.js";
import { eventBus } from "@/eventBus";
import { encryptDeviceInfo } from "./../utilities";

import { store } from "./store";

let refresh_token = false;

window.reLoginRequired = false;

export const makeQueryStringDync = (dataObj) => {
    if (!Object.keys(dataObj).length) return false;

    let queryStr = "?";
    for (let item in dataObj) {
        queryStr += `${item}=${dataObj[item]}&`;
    }
    queryStr = queryStr.substring(0, queryStr.length - 1);
    return queryStr;
};

export const stringifyCaptchaParams = (obj) => {
    if (Object.keys(obj).length <= 0) return;

    let queryStr = "?";

    for (let item in obj) {
        queryStr += `captcha[${item}]=${obj[item]}&`;
    }

    queryStr = queryStr.substring(0, queryStr.length - 1);

    return queryStr;
};

export const makeQueryFormDataDyn = (dataObj) => {
    if (!Object.keys(dataObj).length) return false;
    let obj = new FormData();
    for (let data in dataObj) {
        obj.append(data, dataObj[data]);
    }
    return obj;
};

export const errorServiceHandler = (response) => {
    if ((response.status == 200 || response.status == 201) && response.ok) {
        return response.json();
    } else {
        return response.json();
    }
};

// export const actTokenRefresh = (error, callback) => {
//   console.log("actHandingreDoOperation", callback);
//   let isAnonymousUser = localStorage.getItem("loginType") ? false : true;

//   if (isAnonymousUser == true) {
//     // device token error.errorcode == 6055

//     new configClass().actNewDeviceRegister().then(() => {
//       console.log("Successfully refreshed token");

//       if (window.reLoginRequired == true) {
//         window.reLoginRequired = false;
//         new userClass().subscriberLogin(login_payload, null);
//       }
//     });
//   } else if (isAnonymousUser == false) {
//     // session token error.errorcode == 6066

//     let login_payload = localStorage.getItem("login-payload");
//     login_payload = login_payload ? JSON.parse(login_payload) : null;
//     window.reLoginRequired = true;
//     new userClass().subscriberLogin(login_payload, null);
//   }
// };

// export const errorHandlerMiddleware = (response, callback) => {
//   if (!response.ok) {
//     return response
//       .json()
//       .catch(() => {
//         throw response;
//       })
//       .then((data) => {
//         if (response.status == 401) {
//           console.log(response.url);
//           actTokenRefresh(data, callback);
//         }
//         return data;
//       });
//   }
//   return response.json();
// };

export const paymentRedirect = (response) => {
    if ((response.status == 200 || response.status == 201) && response.ok) {
        return response.json();
    } else if (response.status >= 300 && response.status <= 399) {
        console.log("reached payment redirect", response);
        window.open(response.header.location, "_self");
    } else {
        return response.json();
    }
};

export const toFormUrlEncoded = (object) => {
    return Object.entries(object)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
};

const registerDevice = (counter, callback) => {
    actNewDeviceRegister()
        .then((data) => {
            if (!data.errorcode) {
                console.log("THE DEVICE TOKEN IS SUCCESS FULL ->", data);

                refresh_token = false;

                console.log("MATCH?", window.oldToken == data.success);

                if (!store.getters.subscriberid) {
                    store.commit("setUnauthorizedErrorOccured", false);
                }

                console.log("THE LOGIN VARIABLE ->", window.reLoginRequired);

                if (window.reLoginRequired == true) {
                    window.reLoginRequired = false;
                    let login_payload = localStorage.getItem("login-payload");
                    console.log("THE PAYLOAD ->", login_payload);
                    login_payload = login_payload ? JSON.parse(login_payload) : null;
                    login_payload.autologin = true;
                    login_payload.deviceos = getDeviceOS();

                    const currentProfileId = localStorage.getItem("currentProfileId");
                    console.log("THE RELOGIN PAYLOAD --- > ", currentProfileId);
                    if (currentProfileId) {
                        login_payload.params.profileid = currentProfileId
                    }

                    console.log("THE RELOGIN PAYLOAD --- > OBJECT ", login_payload);


                     eventBus.$emit("subscriberLogin", login_payload);

                    eventBus.$on("login-response", (data) => {
                        localStorage.setItem("sessionToken", data.success);

                        store.commit("setUnauthorizedErrorOccured", false);

                        let lang = localStorage.getItem("setDisplayLanguageCode");
                        eventBus.$emit("check-for-series-route", lang);

                        eventBus.$emit("re-login-success", true);

                        setTimeout(() => {
                            eventBus.$emit("rerender-template");
                        }, 1000);

                        eventBus.$off("login-response");
                    });
                }

                // console.log("callback parameter", callback);

                // callback();

                // console.log("callback execution started");
            } else if (data.errorcode == 6055 && counter > 0) {
                registerDevice(counter - 1);
            } else {
                refresh_token = false;
                window.reLoginRequired = false;
            }
        })
        .catch((error) => {
            console.log("error device token", error);
            window.reLoginRequired = false;
            refresh_token = false;
        });
};

export const actTokenRefresh = (callback) => {
    console.log("ACT FUNC CALL");

    // To identify user type (anonymous or not).
    let isAnonymousUser = localStorage.getItem("subscriberDetails") ? false : true;
    // If anonymous user call device register api to get new device token.
    if (isAnonymousUser == true || window.reLoginRequired == true) {
        if (!refresh_token) {
            console.log("Inside anonymous user type");
            refresh_token = true;
            console.log("callback from actTokenRefresh", callback);
            registerDevice(2, callback);
        }
    }
    //If not anonymous user call login api to get new session token.
    else if (isAnonymousUser == false) {
        console.log("Inside non anonymous user type");

        window.reLoginRequired = true;

        registerDevice(2, callback);

       // eventBus.$emit("subscriberLogin", login_payload);
    }
};

let isRefreshing = false;

export const errorHandlerMiddleware = (response) => {
    if (!response.ok) {
        return response
            .json()
            .catch(() => {
                throw response;
            })
            .then((data) => {
                data.statusCode = response.status;

                if (response.status === 401 && !isRefreshing) {
                    isRefreshing = true; // Prevent multiple calls


                    // Function to get new Token on 401 status code.
                    actTokenRefresh();

                    // Reset after 3 seconds
                    setTimeout(() => {
                        isRefreshing = false;
                    }, 4000);
                }
                return data;
            });
    }
    return response.json();
};


const actNewDeviceRegister = () => {
    console.log("ACT NEW DEVICE REGISTER ---");

    return new Promise((resolve, reject) => {
        // localStorage.removeItem("random");
        encryptDeviceInfo()
            .then((response) => {
                store.dispatch("deviceRegister", response).then((response) => {
                    if (!response.errorcode) {
                        window.activeToken = true;
                        store.commit("setToken", response.success);
                        localStorage.setItem("deviceToken", response.success);
                        console.log("stored new device token to local storage");
                        let lang = localStorage.getItem("setDisplayLanguageCode");
                        eventBus.$emit("check-for-series-route", lang);
                        eventBus.$emit("featured-carousel");
                    }
                    resolve(response);
                });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const setHeader = (contentType = null, type = null) => {
    const header = {};

    if (contentType && contentType === "json") {
        header["Content-Type"] = "application/json";
    } else if (contentType === "formUrl") {
        header["Content-Type"] = "application/x-www-form-urlencoded";
    }

    if (store.getters.subscriberid && type != "deviceToken") {
        header["X-SESSION"] = `${localStorage.getItem("sessionToken")}`;
    } else {
        header["Authorization"] = `Bearer ${localStorage.getItem("deviceToken")}`;
    }

    // console.log("SET HEADER METHOD -->", header);

    return header;
};

export const getDeviceOS = () => {
    console.log("inside device OS  call-----------", navigator);

    let deviceOS = "";

    if (navigator.userAgent.indexOf("Win") != -1) {
        deviceOS = "WINDOWS";
    }

    if (navigator.userAgent.indexOf("Macintosh") != -1) {
        deviceOS = "MACOS";
    }

    if (navigator.userAgent.indexOf("Linux") != -1) {
        deviceOS = "LINUX";
    }

    if (navigator.userAgent.indexOf("Android") != -1) {
        deviceOS = "ANDROID";
    }

    if (navigator.userAgent.indexOf("like Mac") != -1) {
        deviceOS = "MACOS";
    }

    if (!deviceOS) {
        deviceOS = "OTHER";
    }

    console.log("what is th current OS ======================", deviceOS);

    return deviceOS;
};