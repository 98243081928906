import {
	errorServiceHandler,
	toFormUrlEncoded,
	makeQueryStringDync,
	errorHandlerMiddleware,
	setHeader,
	stringifyCaptchaParams,
} from "../../helper";

const actions = {
	listPaymentGateways: (context, payload) => {
		return new Promise((resolve, reject) => {
			let fetchUrl = "";
			let gatewayHeaders = {};
			if (payload) {
				let data = makeQueryStringDync(payload);
				fetchUrl = `${context.getters.vChargeBaseUrl}subscriber/v1/gateway${data}`;
				gatewayHeaders = {
					"X-SESSION": `${localStorage.getItem("sessionToken")}`,
				};
			} else {
				fetchUrl = `${context.getters.vChargeBaseUrl}subscriber/v1/gateway`;
				gatewayHeaders = {
					Authorization: `Bearer ${localStorage.getItem("deviceToken")}`,
				};
			}
			fetch(fetchUrl, {
				method: "GET",
				headers: gatewayHeaders,
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	listExternalGateways: (context) => {
		return new Promise((resolve, reject) => {
			fetch(`${context.getters.vChargeBaseUrl}externalapi/v1/gateway`, {
				method: "GET",
				headers: setHeader(),
				headers: {
					Authorization: `Bearer ${localStorage.getItem("deviceToken")}`,
				},
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => reject(error));
		});
	},

	paymentInitiation: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			console.log("payment init", payload.params);
			let data = toFormUrlEncoded(payload.params);
			fetch(`${context.getters.vChargeBaseUrl}subscriber/v1/payment/init/${payload.gatewayid}`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),

				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	paymentConfirmation: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vChargeBaseUrl}callback/v1/${payload.gatewayid}`, {
				method: "POST",
				body: JSON.stringify(payload.params.referencedata),
				headers: setHeader("json"),

				// {
				//   "Content-Type": "application/json",
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	applePaymentConfirmation: (context, payload) => {
		return new Promise((resolve, reject) => {
			// if (!payload) {
			// 	reject({ error: "payload not found" });
			// }

			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vChargeBaseUrl}callback/v1/applepay`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),

				// {
				//   "Content-Type": "application/json",
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	telcoPaymentInititation: (context, payload) => {
		return new Promise((resolve, reject) => {
			let data = toFormUrlEncoded(payload.params);
			fetch(`${context.getters.vChargeBaseUrl}externalapi/v1/payment/init/${payload.gatewayid}`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl", "deviceToken"),

				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => reject(error));
		});
	},

	telcoPaymentConfirmation: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload.params);
			fetch(`${context.getters.vChargeBaseUrl}externalapi/v1/payment/callback/${payload.gatewayid}`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl", "deviceToken"),

				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	OTPPaymentConfirmation: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload.params);
			fetch(`${context.getters.vChargeBaseUrl}callback/v1/${payload.gatewayid}`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),

				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	telcoPaymentDetail: (context, payload) => {
		return new Promise((resolve, reject) => {
			fetch(`${context.getters.vChargeBaseUrl}externalapi/v1/payment/${payload.referenceid}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("deviceToken")}`,
				},
				// setHeader(),


			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => reject(error));
		});
	},

	telcoPaymentDetail: (context, payload) => {
		return new Promise((resolve, reject) => {
			fetch(`${context.getters.vChargeBaseUrl}externalapi/v1/payment/${payload.referenceid}`, {
				method: "GET",
				headers: setHeader(),

				// {
				//   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => reject(error));
		});
	},

	listSubscription: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			if (payload.subscriptionstatus !== "ALL") {
				let data = makeQueryStringDync(payload);
				fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscription${data}`, {
					method: "GET",
					headers: setHeader(),

					// {
					//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
					// },
				})
					.then(errorHandlerMiddleware)
					.then((data) => {
						resolve({ data });
					})
					.catch((error) => {
						reject(error);
					});
			} else {
				fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscription`, {
					method: "GET",
					headers: setHeader(),

					// {
					//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
					// },
				})
					.then(errorHandlerMiddleware)
					.then((data) => {
						resolve({ data });
					})
					.catch((error) => {
						reject(error);
					});
			}
		});
	},

	listPurchases: (context) => {
		return new Promise((resolve, reject) => {
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/purchase`, {
				method: "GET",
				headers: setHeader(),

				// {
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	paymentDetail: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			fetch(`${context.getters.vChargeBaseUrl}subscriber/v1/payment/${payload.referenceid}`, {
				method: "GET",
				headers: setHeader(),

				// {
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	telcoSTCBH: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vChargeBaseUrl}pgclienthandler/v1/telcostcbh/accesstoken`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),

				// {
				//   "Content-Type": "application/x-www-form-urlencoded",
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	getCardDetails: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				return;
			}

			const data = stringifyCaptchaParams(payload.captcha);

			fetch(`${context.getters.vChargeBaseUrl}subscriber/v1/carddetails/${payload.gatewayId}/${data}`, {
				headers: setHeader(),
			})
				.then(errorHandlerMiddleware)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	validateTheSession: (context, payload) => {
		//alert("invalidate session")
		return new Promise((resolve, reject) => {
			let data = toFormUrlEncoded(payload);
			//vcharge.mobiotics.com/testv1/subscriber/v1/checkout/applesessiontoken'
			fetch(`${context.getters.vChargeBaseUrl}subscriber/v1/checkout/applesessiontoken`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),

				// headers: {
				// 	"Content-Type": "application/json",
				// 	"X-SESSION": `${localStorage.getItem("sessionToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	subscriptionSync: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			//let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vChargeBaseUrl}telco/v1/subscriptionsync/telcomobily/noorplay`, {
				method: "POST",
				body: JSON.stringify(payload),
				headers: setHeader("json"),

				// {
				//   "Content-Type": "application/json",
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`,
				// },
			})
				.then(errorHandlerMiddleware)
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
	// getApplePaySession(context, url) {
	// 	return new Promise(function (resolve, reject) {
	// 		var xhr = new XMLHttpRequest();
	// 		xhr.open('POST', `${context.getters.vChargeBaseUrl}subscriber/v1/checkout/applesessiontoken`);
	// 		xhr.onload = function () {
	// 			if (this.status >= 200 && this.status < 300) {
	// 				resolve(JSON.parse(xhr.response));
	// 			} else {
	// 				reject({
	// 					status: this.status,
	// 					statusText: xhr.statusText
	// 				});
	// 			}
	// 		};

	// 		xhr.onerror = function () {
	// 			reject({
	// 				status: this.status,
	// 				statusText: xhr.statusText
	// 			});
	// 		};

	// 		xhr.setRequestHeader("Content-Type", setHeader("formUrl"), "application/json");
	// 		xhr.send(JSON.stringify({ validationUrl: url }));
	// 	});
	// },
};

export default {
	actions,
};
